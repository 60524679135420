import { useAuth } from "../Context/AuthenticationContext";
import { useEnv } from "../Context/EnvContext";
import { API_M3_PATH, useApi } from "./useApi";

interface useSentOrdersI {
    getAllSentOrders: (options?: GetAllSentOrdersOptions) => Promise<SentOrder[]>,
    getSentOrderDetail: (options: GetSentOrderDetailOptions) => Promise<SentOrderDetail[]>
}

export interface SentOrder {
    numeroOrdine: string;
    address: string;
    city: string;
    customerId: string;
    dataOrdine: string;
    dataSpedizione: string;
    importo: number;
    note: string;
    ragioneSociale: string;
    salesman: string;
    salesmanId: string;
    items: number;
    alcool: boolean;
}

export interface SentOrderDetail {
    company: string; // "DED",
    numeroordine: string; // "20231100002179",
    importo: number; // 11.84,
    qtaKg: number; // 2,
    articolo: string; // "03000023",
    importoListino: number; // 11.84,
    unmis: string; // "PZ",
    numeroriga: string; // "1",
    qta: number; // 2,
    sconto1: number; // 0,
    articoloDes: string; // "TUORLO SPECIALE brick 1kg - (ctx6kg)"
}
interface GetAllSentOrdersOptions {
    fromDate?: string,
    toDate?: string
}
interface GetSentOrderDetailOptions {
    orderId: string
}

export const useSentOrders = (): useSentOrdersI => {
    const { callApi } = useApi();
    const { getEnv } = useEnv();
    const { loginData } = useAuth();

    const getAllSentOrders = async ({ fromDate, toDate }: GetAllSentOrdersOptions = {}): Promise<SentOrder[]> => {
        if (!loginData) {
            throw new Error('Missing loginData!');
        }
        const { erpId, managedSalesmen } = loginData.jwt;
        // Get base gateway url
        const gatewayBase = getEnv('GATEWAY_BASE_URL');
        // Prepare url
        const url = new URL(`${gatewayBase}/${API_M3_PATH}/salesmen/${erpId}/orders`);
        if (managedSalesmen.length > 0) {
            url.searchParams.append('managedSalesmen', managedSalesmen.join(','));
        }
        if (fromDate) {
            url.searchParams.append('fromDate', fromDate);
        }
        if (toDate) {
            url.searchParams.append('toDate', toDate);
        }

        return await callApi({
            url: url.toString(),
            method: 'GET'
        });
    }

    const getSentOrderDetail = async (options: GetSentOrderDetailOptions): Promise<SentOrderDetail[]> => {
        const { orderId } = options;
        if (!orderId) {
            throw new Error('Missing orderId!');
        }
        if (!loginData) {
            throw new Error('Missing loginData!');
        }
        const { erpId, managedSalesmen } = loginData.jwt;
        const gatewayBase = getEnv('GATEWAY_BASE_URL');
        // Prepare url
        const url = new URL(`${gatewayBase}/${API_M3_PATH}/salesmen/${erpId}/orders/${orderId}`);
        if (managedSalesmen.length > 0) {
            url.searchParams.append('managedSalesmen', managedSalesmen.join(','));
        }
        return await callApi({
            url: url.toString(),
            method: 'GET'
        });
    }
    return { getAllSentOrders, getSentOrderDetail }
}