import { DraftOrder } from "../hooks/useDraftOrders";
import { Address, Product } from "../hooks/useNewOrder";
import { OrderState } from "../redux/order/order";

export const mergeProducts = ({ allProducts, stateProducts }: { allProducts: Product[], stateProducts: Product[] | null }) => {
    if (!stateProducts) {
        return allProducts;
    }
    for (const stateProduct of stateProducts) {
        const foundRow = allProducts.find((product) => product.itemId === stateProduct.itemId);
        if (!foundRow) {
            // TODO add ionic alert
            alert('item not found!');
            continue;
        }
        foundRow.quantity = stateProduct.quantity;
        foundRow.sconto = stateProduct.sconto;
    }
    return allProducts;
}

export const getAddressRowSelection = ({ allAddresses, shiptoId }: { allAddresses: Address[], shiptoId: number | undefined }) => {
    if (shiptoId === undefined || shiptoId === null) {
        return {}
    }
    const findIndex = allAddresses.findIndex((address) => address.shiptoId === shiptoId)
    if (findIndex < 0) {
        // TODO add ionic alert
        console.error(`ShiptoId ${shiptoId} not found!`);
        return {}
    }
    return {
        [findIndex]: true
    }
}

export const calculateFinalPrice = (
    { basePrice, contractPrice, assortmentPrice }: { basePrice: number, contractPrice: number, assortmentPrice: number }
) => {
    // contractPrice -> assortmentPrice -> basePrice
    if (contractPrice && contractPrice > 0) {
        return contractPrice
    } else if (assortmentPrice && assortmentPrice > 0) {
        return assortmentPrice
    } else {
        return basePrice;
    }
}

export const convertBaseCurrency = (baseCurrency?: string) => {
    if (!baseCurrency) {
        return '€';
    }
    if (baseCurrency === 'EUR') return '€'
    return "?"
}

export const calculateTotalRowsPrice = (products: Product[] | null) => {
    if (!products) {
        return 0;
    }
    return products.reduce<number>((acc, product) => {
        acc += calculateTotalRowPrice(product);
        return acc;
    }, 0);
}

export const calculateTotalRowPrice = (product: Product | null) => {
    if (!product) {
        return 0;
    }
    const { basePrice, contractPrice, assortmentPrice, quantity, sconto } = product;
    if (!quantity) {
        return 0;
    }
    const priceAllQuantity = calculateFinalPrice({ basePrice, contractPrice, assortmentPrice }) * quantity;
    const discount = sconto > 0 ? priceAllQuantity * (sconto / 100) : 0;
    return (priceAllQuantity - discount);
}

export const calculateTotalRowWeight = (product: Product | null) => {
    if (!product) {
        return 0;
    }
    const { convRate, pzConf, quantity } = product;
    return convRate * pzConf * (quantity ?? 0);
}

export const calculateTotalRowsWeight = (products: Product[] | null) => {
    if (!products) {
        return 0;
    }
    return products.reduce<number>((acc, product) => {
        acc += calculateTotalRowWeight(product);
        return acc;
    }, 0)
}

export const convertNumberToLocale = (price: number) => {
    if (price === undefined || price === null) {
        return;
    }
    return price.toLocaleString('it-IT', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 3
    })
}

export const completeAddress = (addressObj: Address | null) => {
    if (!addressObj) {
        return 'ERROR: Missing address';
    }
    return `${addressObj.address} ${addressObj.city} ${addressObj.cap} (${addressObj.provinca})`
}

export const convertToDraftOrder = ({ orderState, checkForSend = false }: { orderState: OrderState, checkForSend?: boolean }): DraftOrder => {
    const { id, customer, products, deliveryAddress, deliveryDate, note } = orderState;
    if (!customer) {
        throw Error('Need at least customer data');
    }
    const customerPart = {
        customerId: customer.customerId,
        businessName: customer.description,
        address: customer.address,
        city: customer.city,
    }
    let productsPart;
    if (products) {
        productsPart = {
            amount: Number(calculateTotalRowsPrice(products).toFixed(2)),
            weight: Number(calculateTotalRowsWeight(products)),
            productRows: products.map((product) => {
                return {
                    amount: Number(calculateTotalRowPrice(product).toFixed(2)),
                    weight: Number(convertNumberToLocale(calculateTotalRowWeight(product))),
                    productId: product.itemId,
                    fullPrice: Number((product.basePrice * (product.quantity ?? 0)).toFixed(2)),
                    unitOfMeasure: product.um,
                    quantity: product.quantity ?? 0,
                    discount: product.sconto,
                    warehouse: product.warehouse
                }
            })
        }
    }
    let addressPart;
    if (deliveryAddress) {
        addressPart = {
            shipToId: deliveryAddress.shiptoId
        }
    }
    let datePart;
    if (deliveryDate) {
        datePart = {
            deliveryDate: Math.round(new Date(deliveryDate).getTime() / 1000), // Get ts in seconds
        }
    }
    let notePart;
    if (note) {
        notePart = {
            note: note,
        }
    }

    if (checkForSend) {
        if (!productsPart || !addressPart || !datePart) {
            throw new Error('Incomplete Order!');
        }
    }

    return {
        id,
        ...customerPart,
        ...productsPart,
        ...addressPart,
        ...datePart,
        ...notePart
    }
}
