import { useEffect, useMemo, useRef, useState } from "react";
import CustomTable, { dateFilterFn } from "./CustomTable"
import { ColumnDef, ColumnSort, Row, VisibilityState, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { convertIrcaDate, } from "../../utils/helpers";
import { CustomerOrder, CustomerOrderDetail, useCustomer } from "../../hooks/useCustomer";
import { IonButton, IonIcon, IonText, useIonModal } from "@ionic/react";
import { convertBaseCurrency, convertNumberToLocale } from "../../utils/newOrderHelpers";
import { documentTextOutline } from "ionicons/icons";
import { useMobileMediaQuery } from "../../hooks/useAppMediaQuery";
import ModalDetailTable from "../../pages/Materiali/SchedeCliente/ModalDetailTable";
import NoteButton from "../NoteButton/NoteButton";


interface CustomerOrdersTableI {
    customerId: string;
}

const CustomerOrdersTable: React.FC<CustomerOrdersTableI> = ({ customerId }) => {
    const initData = useRef(false);
    const [customerOrders, setCustomerOrders] = useState<CustomerOrder[]>([]);
    const [sorting, setSorting] = useState<ColumnSort[]>([/* { id: 'favorite', desc: true } */]);
    const { getOrders, getOrderDetail } = useCustomer();
    const isMobile = useMobileMediaQuery();
    // state for the modal
    const [detailData, setDetailData] = useState<any>();
    const [detailTitle, setDetailTitle] = useState<string>();
    const columnsOrderDetail = useMemo<ColumnDef<CustomerOrderDetail, any>[]>(
        () => [
            {
                id: 'numeroRigaId',
                header: 'N. Riga',
                accessorKey: 'numeroriga'
            },
            {
                header: 'Codice',
                accessorKey: 'articolo'
            },
            {
                header: 'Descrizione',
                accessorKey: 'articoloDes',
                meta: {
                    mobileHeader: true
                }

            },
            {
                header: 'Importo listino',
                accessorFn: (row) => `${convertBaseCurrency('EUR')} ${convertNumberToLocale(row.importoListino)}`
            },
            {
                header: 'Importo',
                accessorFn: (row) => `${convertBaseCurrency('EUR')} ${convertNumberToLocale(row.importo)}`
            },
            {
                header: 'Sconto',
                accessorFn: (row) => {
                    const percent = Math.floor((row.sconto1 / row.importoListino) * 100);
                    return `${convertBaseCurrency('EUR')} ${convertNumberToLocale(row.sconto1)} ${percent ? `(${percent}%)` : ''}`
                }
            },
            {
                header: 'Quantità',
                accessorFn: (row) => `${row.qta} ${row.unmis} `
            },
            {
                header: 'Peso',
                accessorFn: (row) => `${row.qtaKg} KG`
            },
        ],
        []
    );
    const [sortingDetail, setSortingDetail] = useState<ColumnSort[]>([{ id: 'numeroRigaId', desc: false }]);
    const [presentModal, dismissModal] = useIonModal(ModalDetailTable, {
        onDismiss: () => {
            setDetailTitle(undefined);
            setDetailData(undefined);
            dismissModal();
        },
        detailColumnDefinition: columnsOrderDetail,
        detailData,
        title: detailTitle,
        sorting: sortingDetail,
        setSorting: setSortingDetail
    });

    // Define columns for the table
    const columnsOrder = useMemo<ColumnDef<CustomerOrder, any>[]>(
        () => [
            {
                accessorKey: 'numeroordine',
                header: 'N. Ordine',
                meta: {
                    mobileHeader: true,
                }
            },
            {
                header: 'Inviato',
                accessorFn: (row) => {
                    return convertIrcaDate(row.dataordine);
                },
                cell: ({ getValue }) => {
                    const value = getValue();
                    return value ? value.toLocaleDateString('it-IT') : '---'; // TODO: add correct locale from app
                }
            },
            {
                id: 'dataSpedizioneId',
                header: 'Spedito',
                accessorFn: (row) => {
                    return convertIrcaDate(row.dataSpedizione);
                },
                cell: ({ getValue }) => {
                    const value = getValue();
                    return value ? value.toLocaleDateString('it-IT') : '---'; // TODO: add correct locale from app
                },
                filterFn: dateFilterFn
            },
            {
                header: 'Agente',
                accessorKey: 'salesman'
            },
            {
                header: 'Destinatario',
                accessorFn: (row) => {
                    return {
                        bold: row.ragioneSociale,
                        normal: `${row.city} ${row.address}`
                    }
                },
                cell: ({ getValue }) => {
                    const value = getValue();
                    return (
                        <IonText>
                            <p className="ion-no-margin">
                                <strong>{value.bold}</strong>
                            </p>
                            <p className="ion-no-margin">
                                {value.normal}
                            </p>
                        </IonText>
                    );
                },
                enableSorting: false,
            }, {
                header: 'Importo',
                accessorFn: (row) => `${convertBaseCurrency('EUR')} ${convertNumberToLocale(row.importo)}`,

            },
            {
                header: 'Peso',
                accessorFn: (row) => `${row.qtaKg} KG`
            },
            {
                header: 'Note',
                accessorKey: 'note',
                cell: ({ getValue }) => {
                    const note = getValue();
                    return <NoteButton note={note.trim()}></NoteButton>
                },
                enableSorting: false,
            }
        ],
        []
    );

    // Define column visibility
    const [columnVisibility] = useState<VisibilityState>({
        company: false,
    })

    const table = useReactTable({
        data: customerOrders, // Define data source
        columns: columnsOrder, // Define all column props
        state: {
            columnVisibility,
            sorting,
        },
        onSortingChange: setSorting,
        // onGlobalFilterChange: setGlobalFilter, // Used to enable global filter
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(), // Used to enable pagination
        getFilteredRowModel: getFilteredRowModel(), // Used to enable filtering
        getSortedRowModel: getSortedRowModel(), // Enable sorting
        //  debugTable: true, // TODO: remove in production
    });

    /**
     * Use Effect 
     */
    useEffect(() => {
        if (initData.current) {
            return;
        }
        initData.current = true;
        (async () => {
            setCustomerOrders(await getOrders({ customerId }))
        })()
    }, [])


    const _showOrderDetail = async (row: Row<CustomerOrder>) => {
        const orderSelected = row.original;
        const result = await getOrderDetail({
            customerId,
            orderId: orderSelected.numeroordine
        })
        // set data for the modal
        setDetailTitle(`Dettaglio ordine ${orderSelected.numeroordine}`);
        setDetailData(result);
        presentModal({
            id: 'detail-customer-modal'
        });
    }

    return (
        <CustomTable table={table} totalLabel="Ordini totali: " clickRowCallback={_showOrderDetail}></CustomTable>
    )
}

export default CustomerOrdersTable;