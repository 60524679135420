import { IonChip, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonItem, IonRow, IonText, useIonModal } from "@ionic/react";
import { useEffect, useMemo, useRef, useState } from "react";
import { SentOrder, SentOrderDetail, useSentOrders } from "../../../hooks/useSentOrders";
import CustomTable, { dateFilterFn } from "../../../components/Table/CustomTable";
import { ColumnDef, ColumnSort, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, Row, useReactTable } from "@tanstack/react-table";
import { convertBaseCurrency, convertNumberToLocale } from "../../../utils/newOrderHelpers";
import { searchOutline } from "ionicons/icons";
import { VisibilityState } from "@tanstack/react-table";
import DatePicker from "../../../components/DatePicker";
import { convertIrcaDate } from "../../../utils/helpers";
import NoteButton from "../../../components/NoteButton/NoteButton";
import ModalDetailTable from "../../Materiali/SchedeCliente/ModalDetailTable";

const OrdiniInviati: React.FC = () => {
  const firstInit = useRef(true);
  const { getAllSentOrders, getSentOrderDetail } = useSentOrders();
  const [sentOrders, setSentOrders] = useState<any[]>([]);
  const [globalSearch, setGlobalSearch] = useState('');
  // Define column visibility
  const [columnVisibility] = useState<VisibilityState>({
    products: false,
  })
  const [fromDate, setFromDate] = useState<string | null>(null);
  const [toDate, setToDate] = useState<string | null>(null);
  // state for the modal
  const [detailData, setDetailData] = useState<any>();
  const [detailTitle, setDetailTitle] = useState<string>();
  // Define columns for the table
  const columns = useMemo<ColumnDef<SentOrder, any>[]>(
    () => [
      {
        accessorKey: 'numeroOrdine',
        header: 'N. Ordine',
        meta: {
          mobileHeader: true,
        }
      },
      {
        header: 'Inviato il',
        accessorFn: (row) => {
          return convertIrcaDate(row.dataOrdine);
        },
        cell: ({ getValue }) => {
          const value = getValue();
          return value ? value.toLocaleDateString('it-IT') : '---'; // TODO: add correct locale from app
        }
      },
      {
        id: 'dataSpedizioneId',
        header: 'Spedito il',
        accessorFn: (row) => {
          return convertIrcaDate(row.dataSpedizione);
        },
        cell: ({ getValue }) => {
          const value = getValue();
          return value ? value.toLocaleDateString('it-IT') : '---'; // TODO: add correct locale from app
        },
        filterFn: dateFilterFn
      },
      {
        header: 'Agente',
        accessorKey: 'salesman'
      },
      {
        header: 'Codice',
        accessorKey: 'customerId'
      },
      {
        id: 'destinatarioId',
        header: 'Destinatario',
        accessorFn: (row) => {
          return {
            bold: row.ragioneSociale,
            normal: `${row.city} ${row.address}`
          }
        },
        cell: ({ getValue }) => {
          const value = getValue();
          return (
            <IonText>
              <p className="ion-no-margin">
                <strong>{value.bold}</strong>
              </p>
              <p className="ion-no-margin">
                {value.normal}
              </p>
            </IonText>
          );
        },
        enableSorting: false,
        filterFn: (row, id, filterValue) => {
          const value = row.getValue(id) as { bold: string; normal: string };
          const searchTerm = filterValue.toLowerCase();
          return (
            value?.bold?.toLowerCase().includes(searchTerm) ||
            value?.normal?.toLowerCase().includes(searchTerm)
          );
        },
      },
      {
        header: 'Pezzi',
        accessorKey: 'items'
      }, {
        header: 'Importo',
        accessorKey: 'importo',
        cell: ({ getValue }) => {
          return `${convertBaseCurrency('EUR')} ${convertNumberToLocale(getValue())}`
        }
      }, {
        header: 'Blocco',
        accessorKey: 'alcool',
        cell: ({ getValue }) => {
          return getValue() ? (
            <IonChip color='primary'>Alcool</IonChip>
          ) : null
        }
      },
      {
        header: 'Note',
        accessorKey: 'note',
        cell: ({ getValue }) => {
          const note = getValue();
          return <NoteButton note={note}></NoteButton>
        },
        enableSorting: false,
      }
    ],
    []
  );

  const table = useReactTable({
    data: sentOrders, // Define data source
    columns, // Define all column props
    state: {
      columnVisibility
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(), // Used to enable pagination
    getFilteredRowModel: getFilteredRowModel(), // Used to enable filtering
    getSortedRowModel: getSortedRowModel(), // Enable sorting
    //  debugTable: true, // TODO: remove in production
  })
  // Define column of detail
  const columnsSentOrderDetail = useMemo<ColumnDef<SentOrderDetail, any>[]>(
    () => [
      {
        id: 'numeroRigaId',
        header: 'N. Riga',
        accessorKey: 'numeroriga',
      },
      {
        header: 'Codice',
        accessorKey: 'articolo'
      },
      {
        header: 'Descrizione',
        accessorKey: 'articoloDes',
        meta: {
          mobileHeader: true
        }

      },
      {
        header: 'Importo listino',
        accessorFn: (row) => `${convertBaseCurrency('EUR')} ${convertNumberToLocale(row.importoListino)}`
      },
      {
        header: 'Importo',
        accessorFn: (row) => `${convertBaseCurrency('EUR')} ${convertNumberToLocale(row.importo)}`
      },
      {
        header: 'Sconto',
        accessorFn: (row) => {
          const percent = Math.floor((row.sconto1 / row.importoListino) * 100);
          return `${convertBaseCurrency('EUR')} ${convertNumberToLocale(row.sconto1)} ${percent ? `(${percent}%)` : ''}`
        }
      },
      {
        header: 'Quantità',
        accessorFn: (row) => `${row.qta} ${row.unmis} `
      },
      {
        header: 'Peso',
        accessorFn: (row) => `${row.qtaKg} KG`
      },
    ],
    []
  );
  const [sorting, setSorting] = useState<ColumnSort[]>([{ id: 'numeroRigaId', desc: false }]);
  const [presentModal, dismissModal] = useIonModal(ModalDetailTable, {
    onDismiss: () => {
      setDetailTitle(undefined);
      setDetailData(undefined);
      dismissModal();
    },
    detailColumnDefinition: columnsSentOrderDetail,
    detailData,
    title: detailTitle,
    sorting,
    setSorting,
  });

  useEffect(() => {
    if (!firstInit.current) {
      return;
    }
    firstInit.current = false;
    (async () => {
      setSentOrders(await getAllSentOrders());
    })();
  }, [])

  useEffect(() => {
    if (!table) {
      return;
    }
    const destinatarioColumn = table.getColumn('destinatarioId');
    destinatarioColumn?.setFilterValue(globalSearch);
  }, [globalSearch, table]);

  useEffect(() => {
    if (!table) {
      return;
    }
    const dateColumn = table.getColumn('dataSpedizioneId');
    dateColumn?.setFilterValue({
      fromDate: fromDate ? new Date(fromDate) : null,
      toDate: toDate ? new Date(toDate) : null
    });
  }, [fromDate, toDate, table]);

  const _showSentOrderDetail = async (row: Row<any>) => {
    const orderSelected = row.original;
    const result = await getSentOrderDetail({
      orderId: orderSelected.numeroOrdine
    })
    // set data for the modal
    setDetailTitle(`Dettaglio ordine ${orderSelected.numeroOrdine}`);
    setDetailData(result);
    presentModal({
      id: 'detail-customer-modal'
    });
  }

  const _showNote = (note: string) => {
    // TODO:
  }

  const _download = (order: SentOrder) => {
    // TODO:
  }


  return (
    <IonContent>
      <IonGrid class='full-flex-column'>
        <IonRow className="ion-margin-bottom">
          <IonCol>
            <IonText color="primary">
              <h1>ORDINI INVIATI</h1>
            </IonText>
          </IonCol>
        </IonRow>
        <IonRow className="ion-margin-bottom">
          <IonCol size="12" sizeMd="8">
            <IonItem>
              <IonInput
                value={globalSearch ?? ''}
                onIonInput={({ detail: { value } }) => setGlobalSearch(value as string)}
                placeholder="Inserisci un destinatario" // Updated placeholder
              />
              <IonIcon icon={searchOutline} slot="end"></IonIcon>
            </IonItem>
          </IonCol>
          <IonCol size='12' sizeMd="2">
            <DatePicker placeholder="Da Data" onChange={setFromDate}></DatePicker>
          </IonCol>
          <IonCol size='12' sizeMd="2">
            <DatePicker placeholder="A Data" onChange={setToDate}></DatePicker>
          </IonCol>
        </IonRow>
        <IonRow class="flex-fill">
          <CustomTable table={table} totalLabel="Ordini totali: " clickRowCallback={_showSentOrderDetail}></CustomTable>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default OrdiniInviati;