import {
  IonAccordion,
  IonAccordionGroup,
  IonContent,
  IonFooter,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonRow,
  IonText,
  isPlatform
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import APP_PAGES, { isAppPageWithChildren } from "./AppPages";
import './Menu.css';
import MenuItem from "./MenuItem";
import { getAppInfo } from '../../utils/helpers';
import { AppInfo } from '@capacitor/app';
import { useNetwork } from '../../Context/NetworkContext';
import { Capacitor } from '@capacitor/core';
import { useEnv } from '../../Context/EnvContext';

interface MenuI {
  disabled: boolean;
}

const Menu: React.FC<MenuI> = ({ disabled }) => {
  const location = useLocation();
  const { getEnv } = useEnv();
  const [accordionValue, setAccordionValue] = useState<string | null>(null);
  const [appInfo, setAppInfo] = useState<AppInfo>()
  const { isOnline } = useNetwork();

  useEffect(() => {
    if (!location || accordionValue || accordionValue?.length) {
      return;
    }
    APP_PAGES.forEach((appPage, index) => {
      if (isAppPageWithChildren(appPage)) {
        const correctAccordion = appPage.children.findIndex((children) => location.pathname.startsWith(children.url));
        if (correctAccordion !== -1) {
          setAccordionValue(index.toString());
          return;
        }
      }
    });
    (async () => {
      setAppInfo(await getAppInfo())
    })()
  }, []);

  const _renderFooter = () => {
    if (!Capacitor.isNativePlatform()) {
      return null;
    }
    const env = getEnv('ENV');
    return (
      <>
        {appInfo && (
          <IonFooter>
            <IonRow class="ion-justify-content-center">
              <IonText>{env === 'prod' ? '' : `${env} |`} Ver:{appInfo?.version} | Build:{appInfo?.build}</IonText>
            </IonRow>
          </IonFooter>
        )}
      </>
    )
  }


  return (
    <IonMenu disabled={disabled} contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonAccordionGroup value={accordionValue} multiple={true} onIonChange={({ detail: { value } }) => setAccordionValue(value)}>
            {APP_PAGES.map((appPage, index) => {
              const enableLines = index < APP_PAGES.length - 1;
              if (appPage?.onlyMobile && !isPlatform('capacitor')) {
                return null;
              }
              // Check if is a page app with children (no url)
              if (isAppPageWithChildren(appPage)) {
                return (
                  <IonAccordion key={index} value={index.toString()}>
                    <IonItem slot="header">
                      {appPage.icon && <IonIcon icon={appPage.icon} slot="start" />}
                      <IonLabel>{appPage.title}</IonLabel>
                    </IonItem>
                    <IonList slot="content" className="ion-padding" id="sub-inbox-list">
                      {appPage.children?.map((childAppPage, childIndex) => {
                        const key = `${index}-${childIndex}`;
                        let disabled = childAppPage.disabled;
                        if (childAppPage.disableOffline && !isOnline) disabled = true;
                        return (
                          <MenuItem
                            icon={childAppPage.icon}
                            index={parseInt(`${index}${childIndex}`)}
                            title={childAppPage.title}
                            url={childAppPage.url || "/"}
                            key={key}
                            disabled={disabled}
                            onClick={childAppPage.onClick}
                          />
                        );
                      })}
                    </IonList>
                  </IonAccordion>
                );
              }
              // else return a normal app page
              return (
                <MenuItem
                  icon={appPage.icon}
                  index={index}
                  title={appPage.title}
                  url={appPage.url}
                  key={index}
                  enableLines={enableLines}
                  disabled={appPage.disabled}
                  onClick={appPage.onClick}
                />
              );

            })}
          </IonAccordionGroup>
        </IonList>
      </IonContent>
      {_renderFooter()}
    </IonMenu>
  );
};

export default Menu;
