import Helper from "./helper.js";

class Companies {
    constructor({
        knex,
        onlyQuery = false,
    }) {
        this._knex = knex;
        this._onlyQuery = onlyQuery;
    };

    async _handleQueryBuilder(queryBuilder) {
        if (this._onlyQuery) {
            return queryBuilder.toString();
        }
        return Helper.clean(await queryBuilder);
    }
    // Route
    async getCompany({
        companyCode,
    }) {
        if (!companyCode) {
            throw new Error('Missing companyCode param!');
        }
        const result = await this._handleQueryBuilder(this._knex
            .select('*')
            .from('IDED_COMPANY as vwdc')
            .where('vwdc.PREF_AFO', '=', companyCode)
        );
        if (typeof result === 'string') {
            return result;
        }

        const [company] = result;
        return company;
    }
}

export default Companies;